import React, { useEffect } from "react";
import "particles.js";  // Importing as a side effect


function ParticleBackground() {
  useEffect(() => {
    window.particlesJS("particles-js", {
      fps_limit: 0,  
      particles: {
        number: { value: 30 },
        color: { value: "#ffffff" },
        shape: { type: "circle" },
        opacity: { value: 0.5 },
        size: { value: 3 },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: { enable: true, speed: 3 },
      },
    });
  }, []);

  return <div id="particles-js"></div>;
}

export default ParticleBackground;
