import React, { useState, useEffect,useRef   } from "react";
import './Job.css'
import { useParams } from "react-router-dom";
//import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';

const Job = () => {



	/* const form = useRef();

  const sendEmail = (e) => {
		e.preventDefault();
		

    emailjs
      .sendForm('service_g7ui8xj', 'template_bcutpor', form.current, {
        publicKey: 'ELNeOKte4yGnkfYxd',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>
	); */
	



	const [name, setName] = useState('');
  const [email, setEmail] = useState('');
 	const [resumeFile, setResumeFile] = useState(null);
  const [message, setMessage] = useState('');
	const [fileName, setFileName] = useState(''); // New state for file name
	const [responseCode, setResponseCode] = useState(Number); 




	const form = useRef();

	// Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setResumeFile(file);
    setFileName(file ? file.name : ''); // Set file name or empty string
	};
	
	const sendEmail = (e) => {
		e.preventDefault();
		
		// Prepare FormData to include the resume file
    const formData = new FormData(form.current);
    if (resumeFile) {
      formData.append("resume", resumeFile); // Append the resume file
		}
		
		// Create an object to hold the form data
		const templateParams = {
			user_name: name,
			user_email: email,
			resume: resumeFile, // Pass the file directly as 'resume'
		};

		 // Append templateParams to FormData
		 Object.keys(templateParams).forEach((key) => {
			formData.append(key, templateParams[key]);
	});

    emailjs
      .sendForm('service_g7ui8xj', 'template_bcutpor', form.current, {
        publicKey: 'ELNeOKte4yGnkfYxd',
      })
      .then(
        () => {
					console.log('SUCCESS!');
					setResponseCode(200);
        	setMessage('Resume sent successfully!');
        },
        (error) => {
					console.error('FAILED...', error);
					setResponseCode(500);
        	setMessage('Failed to send resume. Please try again.');
        },
			);
			// Clear the message after 5 seconds
			setTimeout(() => {
				setMessage('');
			}, 5000);
  };

  /* const handleFileChange = (e) => {
    const file = e.target.files[0];
    setResumeFile(file);
    setFileName(file ? file.name : ''); // Set file name or empty string if no file
  };

  const handleSubmit = (e) => {
	e.preventDefault();
	
	if (!resumeFile) {
		setMessage('Please attach a resume file.');
		return;
	  }
    
    const templateParams = {
      name: name,
      email: email,
      resume: fileName, // will need to handle this part separately for files
    };
		
    emailjs.send("service_g7ui8xj", "template_bcutpor" , templateParams , "ELNeOKte4yGnkfYxd")
      .then((response) => {
		console.log('SUCCESS!', response.status, response.text);
			setResponseCode(200);
        setMessage('Resume sent successfully!');
      }, (err) => {
		console.log('FAILED...', err);
			setResponseCode(500);
        setMessage('Failed to send resume. Please try again.');
      });
  }; */

	const { id } = useParams();
	const [job, setJob] = useState(null);

	useEffect(() => {
			const fetchItem = async () => {
					try {
							const response = await fetch(`${process.env.PUBLIC_URL}/content.json`);
							const data = await response.json();
							const selectedItem = data.data.find((item) => item.id === parseInt(id));
							setJob(selectedItem);
					} catch (error) {
							alert("Error fetching item data:", error);
					}
			};

			fetchItem();
	}, [id]);

    return (
        <div className="container-fluid">

		
		<div className="row">
		
			<div className="container">
				<div className="row">
					<div className="col-md-2">
					
					</div>
					<div className="col-md-8">
						<h2>{job?.jobTitle}</h2>
						<p>SHANVATECH.COM / {job?.date} / {job?.category}</p>
							<div>
								<h4>Skills & Requirements:</h4>
								
									<ul>
									{job?.skillsAndRequirements.map((skills,index)=>(
										<li key={index}>{skills}</li>
									))}
								</ul>
							</div>
						
						
						<h4>Mandatory Skill:</h4>
						<ul>
						{job?.mandatorySkill.map((mandatorySkills,index)=>(
						  <li>{mandatorySkills}</li>
						))}
						</ul>
						
						<h4>Other Skills:</h4>
						<ul>
							{job?.otherSkills.map((otherSkill,index)=>(
								<li>{otherSkill}</li>
							))}
						</ul>
						<button type="submit" className="btn btn-block btn-primary btn-lg">Please Share Your Resume to hr@shanvatech.com. Thank You</button>
				  		{/* <div className="form">
						  <h2>Submit Your Resume</h2>
						  <form ref={form} onSubmit={sendEmail} action="">
							<div className="form-group">
							  <label htmlFor="name">NAME:</label>
								<input 
									type="text" 
									className="form-control form-control-lg" 
									name="user_name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
									
							</div>
							<div className="form-group">
							  <label htmlFor="email">EMAIL:</label>
								<input 
									type="email" 
									className="form-control form-control-lg" 
									name="user_email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
					 		<div className="file-upload-container">
							  <label  className="custom-file-upload">
								Browse
								<input
									type="file"
									accept=".pdf,.doc,.docx"
									onChange={handleFileChange}   
									required
								/>
							  </label>
								<input 
									type="text"
									className="file-name-input" 
									id="file-name" 
									placeholder="No file selected"
									value={fileName}
									name="resume"
									readOnly
								/>
							</div>   
							<button type="submit" className="btn btn-block btn-primary btn-lg">SUBMIT</button>
							{message && <p className={responseCode === 500 ? "formMessage-error" : "formMessage-success"}>{message}</p>}
						  </form>

						</div>   */}


					</div>
					<div className="col-md-2">

				
					</div>
				</div>
				
			</div>
			
    </div>
		
		
    </div>
		
    )
}

export default Job
