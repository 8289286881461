import { useEffect, useRef } from 'react'
//import { useInView } from 'react-intersection-observer';
import '../../App.css';

const ScrollFade = ({ children }) => {
    /* const { ref, inView } = useInView({
        threshold: 0.5, // Trigger when 10% of the component is visible
        triggerOnce: true,
      }); */

      const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
          else {
            // Remove the 'show' class when the element is out of view
            entry.target.classList.remove('show');
          }
        });
      },
      { 
        root: null, // Observes relative to the viewport
        rootMargin: '10% 0px 10% 0px', // Trigger when element is in the middle of the viewport
        threshold: 0.5, // Adjusts how much of the element should be visible (50%)

        // threshold: 0.1, previouse effect
       } // Adjust threshold as needed
    );

    if (domRef.current) {
        observer.observe(domRef.current);
      }
    
    // Clean up the observer on unmount
    return () =>{
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      };
  }, []);


    return (
        /* <p ref={ref} className={`fade-in ${inView ? 'show' : ''}`}>
        {text}
      </p> */

      <div className="fade-in" ref={domRef}>
      {children}
    </div>
    )
}

export default ScrollFade
