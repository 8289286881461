import React, { useState } from 'react';
import LeafLetMap from '../util-Components/LeafLetMap';
import emailjs from 'emailjs-com';

const ContactUs = () => {


	// State to manage form fields
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: ''
	  });

	const [message,setMessage]=useState('')
	const [responseCode,setresponseCode]=useState('')
	
	  // Handle input changes
	  const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
		  ...prevData,
		  [name]: value
		}));
	  };
	
	  // Handle form submission
	  const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.send(process.env.REACT_APP_INFO_SERVICE_ID, process.env.REACT_APP_INFO_TEMPLATE_ID , formData, process.env.REACT_APP_INFO_PUBLIC_ID)
		  .then((result) => {
			  console.log(result.text);
			  setresponseCode(200);
			  setMessage("Email sent successfully!");
			  setFormData({ name: '', email: '', message: '' }); // Reset form on success
		  }, (error) => {
			  console.log(error.text);
			  setresponseCode(500);
			  setMessage("An error occurred, please try again.");

		  });
		  // Clear the message after 5 seconds
			setTimeout(() => {
				setMessage('');
			}, 5000);
	  };



	return (
		<div className="container-fluid">
			<div className="container">
				<div className="row">
					<div className="col-md-6">

					<div class="contact-info">
					<h2>Contact Us </h2>
						<div class="company-header">
							<img src={`${process.env.PUBLIC_URL}/images/Menu bar.svg`} alt="Company Logo" class="company-logo"/>
							<div class="company-name">shanvatech</div>
						</div>
						<p>
							<i class="fas fa-map-marker-alt"></i> #387/1L-Block 26th Street Anna Nagar, Chennai Tamil Nadu india 600102
						</p>
						<p>
							<i class="fas fa-phone"></i>  +91-9385920441 / +91-9791293086
						</p>
						<p>
							<i class="fas fa-envelope"></i> info@shanvatech.com
						</p>
						<p>
							<i class="fas fa-globe"></i>
							<a href="https://www.shanvatech.com" target="_blank">www.shanvatech.com</a>
						</p>
						<p>
							<i class="fas fa-clock"></i> Mon - Fri: 9:00 AM - 6:00 PM
						</p>
					</div>

						
					</div>
					<div className="col-md-6">
						<div style={{ padding: '100px 20px 20px 20px' }} className="form">
							<form onSubmit={sendEmail} action="/action_page.php">
								<div className="form-group">
									<label htmlFor="email">NAME:</label>
									<input 
										type="text"
										name="user_name" 
										className="form-control form-control-lg" 
										value={formData.name} 
										onChange={handleChange} 
										required
									 />
								</div>
								<div className="form-group">
									<label htmlFor="pwd">EMAIL:</label>
									<input 
										type="email" 
										className="form-control form-control-lg" 
										name="user_email"
										value={formData.email}
										onChange={handleChange} 
										required
									/>
								</div>
								<div className="form-group">
									<label htmlFor="pwd">MESSAGE :</label>
									<textarea 
										className="form-control form-control-lg" 
										name="message" 
										value={formData.message}
										onChange={handleChange}
										required
									></textarea>
								</div>

								<button type="submit" className="btn btn-primary btn-sm">SEND MAIL</button>
								{message && <p className={responseCode === 500 ? "formMessage-error" : "formMessage-success"}>{message}</p>}  
							</form>
						</div>
					</div>
				</div>

			</div>
			<LeafLetMap />
		</div>


	)
}

export default ContactUs
