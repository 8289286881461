import { React, useState } from "react";
import './It_service.css'

const ItService = () => {
    const [activeContent, setActiveContent] = useState('content1'); // Default to first content
    return (
        <>
        <div className="container">
            <h2>IT Service</h2>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="image">
                  <img src={`${process.env.PUBLIC_URL}/images/IT service.png`} alt="My Image" />
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Increased Efficiency and Cost Savings</h3>
                  <p>enhances efficiency and savings via standardized processes, resource consolidation, and reduced personnel costs, optimizing operations and maximizing resources.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Improved Service Quality and Consistency</h3>
                  <p>Dedicated teams in shared services deepen expertise for improved service. Standardized levels ensure consistent IT delivery. Centralized governance enhances accountability and control.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Reducing costs and risks</h3>
                  <p>Streamlined processes ensure faster IT responses. Flexible resource allocation meets changing needs. Centralized expertise enables rapid tech adaptation.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Benefits for you and your clients</h3>
                  <p>Centralized management simplifies processes, procurement, and talent access, enhancing client relationships and reducing risks for efficient operations.</p>
                </div>
              </div>

          </div>
        </div>


      <div className="app-container">
        <div className="button-container">
          <button
            className={`toggle-button ${activeContent === 'content1' ? 'active' : ''}`}
            onClick={() => setActiveContent('content1')}
          >
            IT Infrastructure Mgmt
        </button>
          <button
            className={`toggle-button ${activeContent === 'content2' ? 'active' : ''}`}
            onClick={() => setActiveContent('content2')}
          >
            Human Resource Mgmt
        </button>
        <button
            className={`toggle-button ${activeContent === 'content3' ? 'active' : ''}`}
            onClick={() => setActiveContent('content3')}
          >
            Consulting Services
        </button>
        </div>
    
      <div className="container">
          {activeContent === 'content1' && (
            <div className="content">

              <p>Effective IT infrastructure management is essential for a variety of critical functions, including banking transactions, voice and video communication, and aircraft navigation. Without a reliable hardware infrastructure, cloud services, banking systems, social media platforms, and search engines would not exist as we know them. This is why essential services, telecommunications companies, airlines, and even military organizations worldwide require high-availability IT infrastructure. Moreover, companies prioritize cost-effective solutions in today’s dynamic economic and technological landscape. In addition to investing in top-tier equipment, there are ongoing expenses associated with maintenance, necessitating robust IT infrastructure administration supported by ITIL processes.</p>
                
              <h5>Selecting the Appropriate Technology and Equipment:</h5>
              <p>The era of careless spending in IT infrastructure is long gone, and today’s IT leaders face the challenge of making informed decisions that prioritize total cost of ownership (TCO), return on investment (ROI), and adaptability without sacrificing product or service quality. CEOs, CIOs, and CTOs are tasked with the intricate responsibility of making cost-effective decisions while balancing enterprise or customer needs with budgetary constraints. These decisions reflect the vision of the leaders, as only a well-aligned vision can yield the desired ROI. Additionally, constantly evolving technologies and their evolving requirements pose a threat to the longevity and usability of acquired hardware in the future.</p>
              
              <h5>Dynamic Market Trends:</h5>
              <p>IT has evolved from modest business tools to a colossal force linking global corners and even reaching out to the farthest reaches of the universe, monitoring for any incoming signals. From dial-up connections, we now traverse networks with unprecedented speed and bandwidth, epitomizing the phrase “Only imagination is the limit.” Transitioning from numerous servers to a converged infrastructure leveraging industry-proven virtualization technologies, hosting multiple servers on single or clustered hosts, and consolidating legacy data centers into smaller, software-defined data centers are proving to be the foundation of effective and efficient IT procurement and management</p>

              <h5>Addressing Challenges:</h5>
              <p>At ShanvaTech Pvt Ltd., we recognize the complexities faced by IT leaders and strive to empower them with informed decisions. Our core principle revolves around the mantra of “REDUCE – TCO, INCREASE ROI, AGILITY, and QUALITY,” where we prioritize</p>

              <h5>Agility:</h5>
              <p> We understand the importance of future-proofing IT investments. Our partnerships with leading OEMs ensure scalable solutions tailored to your evolving business needs. We guarantee adaptability and customization to accommodate future requirements, ensuring sustained relevance and efficiency.</p>

              <h5>No Compromise on Quality:</h5>
              <p>We refuse to compromise on excellence. Aligned with like-minded OEMs, we offer cutting-edge technology and unparalleled after-sales support. Our commitment to quality extends beyond OEM coverage, with our technical experts providing comprehensive asset management and administration, guaranteeing top-notch performance throughout.</p>

              <h5>TCO Vs ROI:</h5>
              <p>“The company in which you improve most, will be least expensive to you.” With that statement, ShanvaTech believes that it not only delivers cost-effective solutions but also collaborates with industry-leading OEMs to tailor solutions to your specific business needs. By prioritizing agility and quality, we ensure maximum ROI on your IT infrastructure investments for years to come.</p>

              <h5>Go Green initiative:</h5>
              <p>In our pursuit of customer success, we remain environmentally conscious. ShanvaTech is dedicated to minimizing your IT infrastructure’s carbon footprint. Through virtualization and innovative performance enhancements, we transition away from conventional legacy systems, reducing overall operating costs and promoting sustainability.</p>
              
              <p>With a customer-centric approach and zero-outage models, we provide efficient, agile, and eco-friendly enterprise IT solutions, ensuring your success in a rapidly evolving digital landscape.</p>
            </div>
          )}
          {activeContent === 'content2' && (
            <div className="content">
              <p>Human resources play a pivotal role in the success of any organization, regardless of its size or industry. Contemporary industrial and organizational psychology emphasizes leadership support, contingency planning, work-life balance, and talent management. Additionally, social HR aims to align and transform anticipated outcomes with the organization’s business goals and objectives.</p>

            
              <p>From an HR standpoint, the concept of boundary-less organizations stands out as one of the most effective and results-oriented approaches, alongside essential processes like recruitment, management, assessment, development, and employee maintenance. The success of every organization heavily relies on the expertise and manageability of its employees. Currently, both Baby Boomers and Millennials play pivotal roles as key game-changers. It’s crucial to emphasize HR competencies in multicultural environments, utilize technology for collaboration, navigate complex legal compliance issues, and demonstrate return on investment (ROI). Predictive analytics is emerging as a critical tool for HR to recognize and develop business strategies, including retention strategies. Leveraging techniques like the Hawthorne Effect and promoting social collaboration are essential for achieving common organizational goals and competing effectively in today’s strategic landscape.</p>

              <p>High-performance organizations rely on highly talented employees and, equally crucial, determined staff to manage these talented individuals, a responsibility that falls squarely on effective human resources management techniques. Recognizing that every performing employee is an asset while non-performing ones represent liabilities, we at Forscher aim to provide explicit assets for your business. We aspire to play a significant role in your organization’s vision by helping acquire skilled talent from the job market and retaining top-performing employees, essential factors for sustained growth. Forscher Technology Solutions Pvt Ltd operates as an agile organization, staying attuned to current trends, approaches, technologies, and multicultural environments.</p>

              <p>We engage in developmental counseling and knowledge transfer between employees to enhance future responsiveness and identify organizational and employee weaknesses, providing a framework for improving technical abilities—a critical component of HR. Benchmarks across diverse activities and strategic responsibilities in human resources help us focus on essential techniques. Forscher Technology Solutions Pvt Ltd implements tools to evaluate employee performance, align strategies with organizational goals, communicate job responsibilities and missions, and identify key performance indicators (KPIs) within the workplace environment.</p>

              <p>Using the balanced scorecard approach, we measure four areas of business: internal processes, financial performance, customer knowledge, and learning and development. Additionally, we conduct technical performance reviews for recent graduates with a focus on soft skill development. Through this strategically focused HR model, Forscher Technology Solutions Pvt Ltd supports exceptional functional development and outstanding productivity, aiming for a win-win situation for all involved.</p>

              <p>ShanvaTech Leadership Coaching ShanvaTech Pvt Ltd offers individualized business technical coaching, recognized as the fastest and most effective method for enhancing your business. Our approach involves regular meetings with your business coach to meticulously assess your business, strategize optimal methods for achieving maximum results, and implement your action plan with unwavering focus and determination. Our coaching encompasses leadership assessments, industry benchmarking, and comprehensive business evaluations to ensure a holistic approach to your business growth and success.</p>

              <h5>Strategic Advisory Services:</h5>
              <p>We offer strategic advisory services to help clients optimize their vendor management strategies and maximize the value derived from their vendor relationships. Our team provides insights and recommendations based on industry best practices, market trends, and client-specific needs, enabling clients to make informed decisions and achieve their business objectives effectively.</p>

              <h4>Why Choose ShanvaTech as Your Partner?:</h4>

              <p>ShanvaTech simplifies HR tasks by offering accessible and swift solutions for a critical component in every organization. Our approach not only saves costs, time, and effort but also enhances retention rates. We understand that sustaining leadership talent and implementing diverse strategic approaches are fundamental requirements for all organizations. Therefore, ShanvaTech ensures a balanced supply of leaders in an organized manner, aligning with HR’s objective to drive business solutions and achieve tangible results.</p>

              
            </div>
          )}

          {activeContent === 'content3' && (
            <div className="content">
              <p>Our Consulting Services on Leading Technologies empower companies to outline their strategic innovation roadmap, cultivate capabilities to adapt to swiftly changing business landscapes, accelerate their development processes, and optimize market adoption. Our methodology equips us to tackle projects of any nature, size, or intricacy, ensuring comprehensive support tailored to each client’s unique needs.</p>

              <h3>UI/UX Consulting:</h3>
              <p>UX/UI consultation involves collaborating with experts to optimize the user experience (UX) and user interface (UI) design of digital products or services. This service endeavors to elevate diverse facets of businesses’ digital footprint, encompassing improved usability, streamlined navigation, brand consistency, enhanced visual appeal, heightened user engagement, optimized accessibility, usability testing and iteration, device responsiveness, performance optimization, and data-driven decisions. It aims to more effectively align with the needs and preferences of the target audience.</p>

              <p>Overall, UX/UI consultation aims to create digital experiences that are not only visually appealing but also functional, intuitive, and aligned with business goals, ultimately leading to improved user satisfaction and business success.</p>

              <h3>Devops / DevSecops Consulting:</h3>
              <p>DevOps/DevSecOps consulting services entail collaborating with organizations to implement and optimize DevOps and DevSecOps practices. These services are crucial in today’s digital landscape as they foster collaboration between development, operations, and security teams to streamline software delivery, enhance security, and drive business agility.</p>

              <p>The Key elements and importance of DevOps/DevSecOps consulting services includes Assessment and Strategy Development, Automation and Toolchain Integration, Culture and Collaboration Enhancement, Continuous Integration and Continuous Delivery (CI/CD), Security Integration and Automation, Monitoring and Feedback Loops, Scalability and Resilience, Compliance and Governance, Cost Optimization and Business Agility and Innovation</p>

              <p>In summary, DevOps/DevSecOps consulting services play a vital role in helping organizations transform their software development and delivery processes, achieve faster time-to-market, improve security posture, and drive business success in today’s fast-paced digital landscape.</p>

              <h3>Agile Consulting:</h3>
              <p>Agile consulting services are instrumental in guiding organizations through the adoption and optimization of Agile methodologies. Here’s a detailed description of its importance:</p>


              <h5>Transformation Guidance:</h5>
              <p>Agile consultants provide expertise and guidance to organizations transitioning from traditional project management approaches to Agile methodologies. They help define clear objectives, develop implementation roadmaps, and support teams in embracing Agile principles and practices.</p>

              <h5>Improved Time-to-Market:</h5>
              <p> Agile methodologies emphasize iterative development and frequent delivery of working software. Agile consultants help organizations streamline their processes, reduce time spent on non-value-added activities, and accelerate product development cycles, resulting in faster time-to-market for products and features.</p>

              <h5>Enhanced Flexibility and Adaptability:</h5>
              <p>Agile methodologies promote adaptability to changing requirements and market dynamics. Agile consultants assist organizations in building flexible project plans, establishing prioritization frameworks, and fostering a culture of responsiveness, enabling teams to quickly pivot and adjust to evolving business needs.</p>

              <h5>Increased Collaboration and Communication:</h5>
              <p>Agile emphasizes cross-functional collaboration and regular communication among team members and stakeholders. Agile consultants facilitate the adoption of collaborative practices such as daily stand-up meetings, sprint planning sessions, and retrospectives, fostering transparency, alignment, and shared accountability across the organization.</p>

              <h5>Customer-Centric Approach:</h5>
              <p>Agile places a strong emphasis on delivering value to customers through early and continuous delivery of valuable software increments. Agile consultants help organizations adopt customer-centric practices such as user story mapping, user feedback sessions, and customer validation, ensuring that development efforts are aligned with customer needs and preferences.</p>

              <h5>Continuous Improvement Culture:</h5>
              <p>Agile methodologies promote a culture of continuous improvement, where teams regularly reflect on their processes and outcomes and seek opportunities for enhancement. Agile consultants guide organizations in establishing feedback loops, implementing retrospective meetings, and leveraging metrics and data analytics to identify areas for improvement and drive continuous learning and innovation.</p>

              <h5>Risk Mitigation:</h5>
              <p>Agile methodologies offer built-in mechanisms for risk mitigation through incremental delivery, frequent testing, and early validation with stakeholders. Agile consultants assist organizations in identifying and managing project risks proactively, applying Agile practices such as risk-based prioritization, iterative risk assessments, and adaptive planning to mitigate potential threats and uncertainties.</p>

              <h5>Employee Engagement and Empowerment:</h5>
              <p>Agile empowers teams by promoting self-organization, autonomy, and empowerment. Agile consultants help organizations create an environment where teams feel empowered to make decisions, take ownership of their work, and collaborate effectively towards shared goals, leading to higher levels of employee engagement, satisfaction, and retention.</p>

              <h5>Alignment of Business and IT Objectives:</h5>
              <p>Agile methodologies promote closer alignment between business and IT stakeholders by fostering collaboration, shared understanding, and common goals. Agile consultants help bridge the gap between business and IT functions, facilitate collaborative decision-making processes, and ensure that development efforts are aligned with strategic business objectives, resulting in better outcomes and value delivery.</p>

              <h5>Competitive Advantage:</h5>
              <p>In today’s fast-paced and dynamic business environment, Agile practices enable organizations to respond quickly to market changes, deliver innovative solutions, and stay ahead of competitors. Agile consulting services help organizations build Agile capabilities, cultivate an Agile mindset, and harness the full potential of Agile methodologies to gain a competitive edge and drive business success.</p>

              <p>In summary, Agile consulting services play a pivotal role in guiding organizations through Agile transformations, enabling them to realize benefits such as improved time-to-market, enhanced flexibility, increased collaboration, customer-centricity, risk mitigation, employee empowerment, alignment of business objectives, and competitive advantage in today’s rapidly evolving marketplace.</p>

              <h4>Software and Quality Assurance (QA) as a Service</h4>
              <p>At ShanvaTech, we specialize in providing comprehensive software development and support services tailored to meet the unique needs of our clients. With a focus on delivering high-quality solutions and exceptional support, we strive to be the go-to partner for businesses seeking reliable and innovative software solutions.</p>

              <h5>Our Services:</h5>
              <h5>Custom Software Development:</h5>
              <p>We excel in developing bespoke software solutions that are precisely tailored to address the specific requirements and challenges of our clients’ businesses. From conceptualization to implementation, our team works closely with clients to deliver solutions that streamline operations, boost efficiency, and drive growth.</p>

              <h5>Web Development:</h5>
              <p>Our expertise in web development spans across a variety of technologies and platforms. Whether it’s creating responsive websites or dynamic web applications, we leverage the latest tools and frameworks to deliver engaging digital experiences that resonate with users.</p>

              <h5>Mobile App Development:</h5>
              <p>With the proliferation of mobile devices, having a strong presence on smartphones and tablets is essential for businesses. Our mobile app development services cover everything from native app development to cross-platform solutions, ensuring that our clients can reach their audience wherever they are.</p>

              <h5>Software Maintenance and Support:</h5>
              <p>Beyond the initial development phase, we provide ongoing maintenance and support services to ensure that our clients’ software remains robust, secure, and up-to-date. Our dedicated support team is on hand to address any issues promptly and keep the software running smoothly.</p>

              <h5>Technical Consulting:</h5>
              <p>We offer strategic technical consultancy services to help clients navigate the complexities of software development and make informed decisions. Whether it’s selecting the right technologies, designing scalable architectures, or mitigating risks, our experts provide valuable insights and guidance every step of the way.</p>

              <h5>Quality Assurance (QA) as a Service:</h5>
              <p>In addition to development and support, we offer QA as a Service to ensure the reliability and performance of our clients’ software products. Our team of QA experts conducts comprehensive testing to identify and resolve any issues before deployment, minimizing the risk of bugs and ensuring a seamless user experience.</p>

              <h4>Advantages of Choosing Us:</h4>

              <h5>Tailored Solutions:</h5>
              <p>Our custom approach ensures that clients receive software solutions that are specifically designed to meet their unique business needs, providing a competitive edge in their industries.</p>

              <h5>Expertise and Experience:</h5>
              <p>With years of experience and a team of seasoned professionals, we bring a wealth of expertise to every project, guaranteeing high-quality results and reliable support services.</p>

              <h5>Cost-Effectiveness:</h5>
              <p>Outsourcing software development, support, and QA to us offers a cost-effective solution compared to maintaining an in-house team, allowing clients to maximize their resources and focus on their core business activities.</p>

              <h5>Timely Delivery:</h5>
              <p>We are committed to delivering projects on time and within budget, ensuring that our clients can meet their strategic objectives and stay ahead of the competition.</p>

              <h5>Long-Term Partnership:</h5>
              <p>We value long-term relationships with our clients and strive to be a trusted partner every step of the way. Our dedication to client satisfaction and ongoing support fosters collaboration and mutual success.</p>


              <p>At ShanvaTech, we are dedicated to empowering businesses with innovative software solutions and exceptional support services. With a focus on quality, expertise, and partnership, we are committed to helping our clients achieve their goals and drive success in today’s digital world, backed by our comprehensive QA as a Service to ensure the reliability and performance of their software products.</p>



            </div>
          )}
        </div>
      </div>

    </>
    )
}

export default ItService
