import { React, useState } from "react";
import './StaffingService.css'

const StaffingService = () => {
  const [activeContent, setActiveContent] = useState('content1'); // Default to first content

  return (
    <>
        <div className="container">
            <h2>Staffing Service</h2>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="image">
                  <img src={`${process.env.PUBLIC_URL}/images/staffing service.png`} alt="My Image" />
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Matching talent to needs</h3>
                  <p>We carefully match the right talent with your unique business needs, ensuring they have the skills and experience to make a significant impact from day one.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Boosting productivity and efficiency</h3>
                  <p>By providing qualified and qualified workers, we help you optimize your workforce, increase productivity, and achieve your business goals faster. Reducing costs and risks</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Reducing costs and risks</h3>
                  <p>We partner with you to scale your workforce seamlessly as your business grows, providing the talent you need to capitalize on new opportunities.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-2">
                <div className="content-box">
                  <h3>Reducing costs and risks</h3>
                  <p>Our comprehensive staffing solutions can help you minimize hiring costs, manage payroll complexities, and mitigate employment risks.</p>
                </div>
              </div>

          </div>
        </div>


      <div className="app-container">
        <div className="button-container">
          <button
            className={`toggle-button ${activeContent === 'content1' ? 'active' : ''}`}
            onClick={() => setActiveContent('content1')}
          >
            IT STAFF Augumentaion
        </button>
          <button
            className={`toggle-button ${activeContent === 'content2' ? 'active' : ''}`}
            onClick={() => setActiveContent('content2')}
          >
            Master Vendor Program
        </button>
        </div>
    
      <div className="container">
          {activeContent === 'content1' && (
            <div className="content">

              <p>We empower businesses with top-tier IT talent through flexible staff augmentation services.</p>
              <p>Our expert team specializes in connecting companies with skilled IT professionals for their unique projects.
            </p>
              <p>Scale your IT team seamlessly with our on-demand staffing solutions, maximizing efficiency and minimizing risk.</p>

              <h5>Cost-effectiveness:</h5>
              <p>Compared to hiring full-time employees, staff augmentation provides access to specialized skills without the overhead costs (benefits, training, equipment, etc.). Pay only for the time and expertise needed.</p>

              <h5>Flexibility & Scalability:</h5>
              <p>Easily adjust your IT team size based on project requirements or fluctuating workloads. Scale up for peak periods and downsize without long-term commitments.</p>

              <h5>Access to Specialized Skills:</h5>
              <p>Find hard-to-find IT talent with specific expertise for short-term projects or long-term needs. Avoid lengthy recruitment processes and tap into a wider talent pool.</p>

              <h5>Faster Time-to-Market:</h5>
              <p>With readily available skilled professionals, you can accelerate project completion and achieve faster results, gaining a competitive edge.</p>

              <h5>Reduced Risks:</h5>
              <p>Mitigate risks associated with new technologies or projects by bringing in experts for a trial period before full-time commitments.</p>

              <h5>Improved Efficiency & Productivity:</h5>
              <p>Highly qualified professionals can boost team performance and optimize workflows, leading to increased productivity and efficiency.</p>

              <h4>Innovation & Knowledge Sharing: </h4>

              <h5>Improved Efficiency & Productivity:</h5>
              <p>Fresh perspectives and diverse skillsets can bring new ideas and foster innovation within your team.</p>

              <h5>Focus on Core Business:</h5>
              <p>By outsourcing IT staffing needs, you can free up your time and resources to focus on your core business activities.</p>

              <h5>Reduced Management Burden:</h5>
              <p>Less time spent on recruitment, onboarding, and managing full-time IT staff means more time for strategic planning and business growth.</p>
            </div>
          )}
          {activeContent === 'content2' && (
            <div className="content">
              <p>As part of our comprehensive suite of services, we offer a Master Vendor program tailored to meet the evolving needs of our clients. Our Master Vendor program serves as a strategic solution for organizations seeking to streamline their vendor management processes, optimize resource allocation, and drive efficiency across their projects. By leveraging our expertise and experience, clients can benefit from a centralized approach to vendor management, ensuring seamless collaboration and maximum value delivery.</p>

              <h4>Key Services:</h4>
              <h5>Vendor Consolidation:</h5>
              <p>We facilitate the consolidation of multiple vendors into a single, centralized platform, simplifying the management and coordination of resources across various projects. By consolidating vendors, clients can reduce administrative overhead, minimize duplication of efforts, and achieve greater transparency and control over their vendor relationships.</p>

              <h5>Vendor Selection and Evaluation:</h5>
              <p>Our team assists clients in selecting the most suitable vendors for their specific project requirements through a rigorous evaluation process. We conduct thorough assessments of vendors based on criteria such as expertise, experience, capabilities, and track record, ensuring that clients partner with vendors who can deliver exceptional results.</p>

              <h5>Contract Negotiation and Management:</h5>
              <p>We provide support in negotiating favorable contracts with vendors, including terms and conditions, pricing structures, and service level agreements (SLAs). Our goal is to ensure that clients receive the best possible terms while maintaining strong partnerships with their vendors. Additionally, we oversee the ongoing management of vendor contracts to ensure compliance and mitigate risks.</p>

              <h5>Performance Monitoring and Reporting:</h5>
              <p>We implement robust systems for monitoring the performance of vendors, tracking key metrics such as quality, timeliness, and cost-effectiveness. Through regular performance reviews and reporting, we help clients identify areas for improvement, address issues proactively, and optimize vendor relationships to drive continuous improvement and value delivery.</p>

              <h5>Issue Resolution and Escalation:</h5>
              <p>In the event of issues or conflicts with vendors, our team serves as a central point of contact for resolution and escalation. We work closely with clients and vendors to address issues promptly, minimize disruptions to project timelines, and ensure that issues are resolved satisfactorily, maintaining the integrity of the client-vendor relationship.</p>

              <h5>Strategic Advisory Services:</h5>
              <p>We offer strategic advisory services to help clients optimize their vendor management strategies and maximize the value derived from their vendor relationships. Our team provides insights and recommendations based on industry best practices, market trends, and client-specific needs, enabling clients to make informed decisions and achieve their business objectives effectively.</p>

              <h4>Advantages of Our Master Vendor Program:</h4>

              <h5>Streamlined Vendor Management:</h5>
              <p>Our Master Vendor program simplifies the complexity of vendor management by providing a centralized platform for coordination and collaboration, saving clients time, effort, and resources.</p>

              <h5>Cost Savings:</h5>
              <p> By consolidating vendors and negotiating favorable contracts, clients can realize significant cost savings while maintaining high-quality standards and service levels.</p>

              <h5>Enhanced Efficiency:</h5>
              <p>Our program improves efficiency by optimizing resource allocation, minimizing administrative overhead, and facilitating smoother communication and coordination among vendors and stakeholders.</p>

              <h5>Risk Mitigation:</h5>
              <p>We help clients mitigate risks associated with vendor relationships through proactive monitoring, issue resolution, and compliance management, ensuring smooth project execution and delivery.</p>

              <h5>Strategic Insights:</h5>
              <p>Our strategic advisory services offer clients valuable insights and recommendations to optimize their vendor management strategies, stay ahead of market trends, and drive continuous improvement and innovation.</p>
            </div>
          )}
        </div>
      </div>

    </>
  )
}




export default StaffingService
