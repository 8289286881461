import React, { useState, useEffect,useRef   } from 'react';
import './App.css';
import "leaflet/dist/leaflet.css";
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import Home from './components/Users-Component/Home';
import Job from './components/Users-Component/jobComponent/Job';
import PageNotFound from './components/Users-Component/PageNotFound';
import ContactUs from './components/Users-Component/ContactUs';
import StaffingService from './components/Users-Component/StaffingService/StaffingService';
import ItService from './components/Users-Component/IT-service/ItService';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/util-Components/ScrollToTop' 


function App() {
  const [scrollTarget, setScrollTarget] = useState(null); // Track target section ref

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);


  const [content, setContent] = useState([]);
  

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/content.json`) // Fetch the JSON file
            .then((response) => response.json())
            .then((data) => {
              setContent(data)
            })
            .catch((error) => console.error("Error loading content:", error));
    }, []);

    /* const scrollToSection = (sectionRef) => {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }; */

    const scrollToSection = (sectionRef) => {
      setScrollTarget(sectionRef); // Set the target section to scroll to
    };

  return (
    <div className="App">
      
      <Navbar 
      scrollToSection={scrollToSection} 
      section1Ref={section1Ref} 
      section2Ref={section2Ref}
      section3Ref={section3Ref}
      section4Ref={section4Ref}
      section5Ref={section5Ref}
      />
      <ScrollToTop/>
        <Routes>
          <Route 
            path="/" 
            element={
              <Home 
                content={content} 
                section1Ref={section1Ref} 
                section2Ref={section2Ref} 
                section3Ref={section3Ref} 
                section4Ref={section4Ref} 
                section5Ref={section5Ref} 
                scrollTarget={scrollTarget} 
              />
            } 
          
          />
          <Route path="/contactUs" element={<ContactUs />} />    
          <Route path="/job/:id" element={<Job />} />    
          <Route path="*" element={<PageNotFound />} /> 
          <Route path="/staffing-service" element={<StaffingService />} /> 
          <Route path="/it-service" element={<ItService />} /> 
        </Routes>
        

      <Footer/>


    </div>
  );
}

export default App;
