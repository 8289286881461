import React from 'react'
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const Navbar = ({ scrollToSection, section1Ref, section2Ref,section3Ref,section4Ref,section5Ref }) => {

  const navigate = useNavigate();

  const handleScrollToSection = (sectionRef, path = "/") => {
    navigate(path); // Navigate to Home (or other routes)
    scrollToSection(sectionRef); // Set scroll target
  };


    return (
        <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container brand-container">
          <Link to="/" className="navbar-brand" href="#">
            <img src={`${process.env.PUBLIC_URL}/images/Menu bar.svg`} width="70px" height="auto" className="d-inline-block align-top" alt="Logo" />
          </Link>
          <p class="brand-name">shanvatech</p>


          <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
               <li className="nav-item">
                <a className="nav-link nav-pad" onClick={() => handleScrollToSection(section1Ref)} >Our Commitment</a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-pad" onClick={() => handleScrollToSection(section2Ref)} >Why Shanvatech?</a>
              </li>  
              <li className="nav-item">{/*   add active class if u want    */}
                <a className="nav-link nav-pad" onClick={() => handleScrollToSection(section3Ref)}>Service </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-pad" onClick={() => handleScrollToSection(section4Ref)}>Insights</a>
              </li>
          {/*     <li className="nav-item">
                <a className="nav-link nav-pad" >About</a>
              </li>    */}
              <li className="nav-item">
                <a className="nav-link nav-pad" onClick={() => handleScrollToSection(section5Ref)}>Careers</a>
              </li>
              <li className="nav-item">
                <Link to="/contactUs" className="nav-link btn btn-purple" >Contact Us</Link>
              </li>
            </ul>
          </div>

        </div>

      </nav>
    )
}

export default Navbar
