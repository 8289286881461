import React from 'react'
import './ContactForm.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ContactForm = ({ content }) => {

    // Sample data (10 items)
    const contentss = [
        { id: 1, text: "Content 1" },
        { id: 2, text: "Content 2" },
        { id: 3, text: "Content 3" },
        { id: 4, text: "Content 4" },
        { id: 5, text: "Content 5" },
        { id: 6, text: "Content 6" },
        { id: 7, text: "Content 7" },
        { id: 8, text: "Content 8" },
        { id: 9, text: "Content 9" },
        { id: 10, text: "Content 10" },
    ];

    // Settings for the carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            }
            
          ],
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="container WeRreHiring">
                    <h2>Careers</h2>

                {/*     <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="forCareer">
                                <img src={`${process.env.PUBLIC_URL}/images/hands.png`} alt="My Image" />
                            </div>
                        </div>
                        {content.data ?.map((contents, index) => (
                            <div key={index} className="col-md-6 col-lg-3 ">
                                <div className="content-box">
                                    <div className="job-text">
                                        <h3>{contents.jobTitle}</h3>
                                        <div>
                                            {contents.skillsAndRequirements ?.map((skills, subIndex) => (
                                                <p key={subIndex}>
                                                    {skills}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <Link to={`/job/${contents.id}`}><button type="button" className="btn btn-primary careers-knowMore">KNOW MORE</button></Link>
                            </div>
                        ))}
                    </div>     */}

                    {/*  ---------------------------------    */}

                    <div class="row">
                        <div className="col-md-4 col-lg-3">
                            <div className="forCareer">
                                <img src={`${process.env.PUBLIC_URL}/images/hands.png`} alt="My Image" />
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <div className="carousel-container">
                                <Slider {...settings}>
                                    {content.data ?.map((contents, index) => (
                                        <div key={index} className="carousel-item">
                                            <div className="job-text">
                                                <h3>{contents.jobTitle}</h3>
                                                <div>
                                                    {contents.skillsAndRequirements ?.map((skills, subIndex) => (
                                                        <p key={subIndex}>
                                                            {skills}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <Link to={`/job/${contents.id}`}><button type="button" className="btn btn-primary careers-knowMore">KNOW MORE</button></Link>

                                        </div>
                                    ))}

                                </Slider>
                            </div>
                        </div>

                    </div>


                    {/*  --------------------------------------    */}




                </div>

            </div>



        </div>
    )
}

export default ContactForm
