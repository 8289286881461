import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <p><img src={`${process.env.PUBLIC_URL}/images/Logo Transparent BG.png`} width="40" height="40" className="d-inline-block align-top" alt="Logo" />&copy; 2024 Shanvatech.</p>
            </div>
        </footer>
    )
}

export default Footer
